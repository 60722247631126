import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { Modal } from "flwww";
import { withRouter } from "react-router-dom";
import { AppContext } from "../context/AppContext";

class ReserveData extends Component {
  static contextType = AppContext;
  state = {
    sending: "Send Email",
    open: false,
    visible: false,
    visible1: false,
    visible2: false,
    reply: "",
  };
  deleteReserve = () => {
    db.collection("reservations").doc(this.props.data.id).delete();
  }
  confirmReserve = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      confirmed: true,
      staffReply: this.state.reply,
      acknowledgement: new Date()
    });
  };
  confirmCancel = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      cancelled: !this.props.data.cancelled,
    });
  };
  confirmArrival = () => {
    db.collection("test").doc(this.props.data.id).update({
      finished: true,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  confirmDecline = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      declined: true,
      acknowledgement: new Date()
    });
  };

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  toggleModal1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };
  toggleModal2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };

  cancelReservation = () => {
    this.setState(
      {
        sending: "Sending...",
      },
      () => {
        const mailData = {
          name: this.props.data.name,
          date: new Date(this.props.data.date.seconds * 1000),
          pax: this.props.data.pax,
          seatPref: this.props.data.seatPref,
          time: this.props.data.time,
          email: this.props.data.email,
          outlet: this.context.outlet,
        };
        fetch(
          "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/reject",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(mailData),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.message) {
              console.log("Success");
              this.confirmDecline();
              this.toggleModal();
            }
          });
      }
    );
  };

  sendConfirmation = () => {
    const mailData = {
      name: this.props.data.name,
      date: new Date(this.props.data.date.seconds * 1000),
      pax: this.props.data.pax,
      seatPref: this.props.data.seatPref,
      time: this.props.data.time,
      email: this.props.data.email,
      remarks: this.props.data.remarks,
      reply: this.state.reply,
      id: this.props.data.id,
      outlet: this.context.outlet,
    };
    fetch(
      "https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/sendConfirmationMail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mailData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          console.log("Success");
          this.confirmReserve();
          this.toggleModal1();
        } else {
          console.log("failed");
        }
      });
  };
  render() {
    let jsdate = new Date(this.props.data.date.seconds * 1000);
    let date = new Date(this.props.data.date.seconds * 1000).toLocaleString(
      "en-US",
      {
        year: "numeric",
        month: "short",
        day: "numeric",
      }
    );
    let createDate = new Date(
      this.props.data.createdAt.seconds * 1000
    ).toLocaleString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
    let editedDate;
    if (this.props.data.editedAt) {
      editedDate = new Date(
        this.props.data.editedAt.seconds * 1000
      ).toLocaleString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
    let seatPreference;
    if (this.props.data.seatPref === "indoor") {
      seatPreference = "IND";
    } else if (this.props.data.seatPref === "outdoor") {
      seatPreference = "OUT";
    } else {
      seatPreference = "BAR";
    }
    let acknowledgeDateTime = '';
    if (this.props.data.acknowledgement) {
      acknowledgeDateTime = new Date(
        this.props.data.acknowledgement.seconds * 1000
      ).toLocaleString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
    return (
      <div
        className={
          this.props.data.cancelled
            ? "reserve-data-box cancelled"
            : "reserve-data-box"
        }
      >
        <Modal
          centered
          isVisible={this.state.visible}
          toggleModal={this.toggleModal}
        >
          <div className="confirm-body">
            <h3>Confirm Decline?</h3>

            <div className="confirm-buttons">
              <button
                style={{ backgroundColor: "#3ee67e" }}
                onClick={this.cancelReservation}
              >
                Confirm
              </button>
              <button
                style={{ backgroundColor: "#f71b39" }}
                onClick={this.toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          centered
          isVisible={this.state.visible1}
          toggleModal={this.toggleModal1}
        >
          <div className="confirm-body">
            <h3>Reply to guest’s special requests (if any):</h3>
            <div className="form-group full-width" style={{ width: "100%" }}>
              <textarea
                style={{ width: "100%", height: 240 }}
                value={this.state.reply}
                onChange={this.onChange}
                id="reply"
              ></textarea>
            </div>
            <div className="confirm-buttons">
              <button
                style={{ backgroundColor: "#3ee67e" }}
                onClick={this.sendConfirmation}
              >
                Confirm
              </button>
              <button
                style={{ backgroundColor: "#f71b39" }}
                onClick={this.toggleModal1}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <div className="reserve-data">
          <div className="data-details">
            <span style={{ width: 200, fontWeight: "bold" }}>
              {this.props.data.name}
            </span>
            <span
              onClick={() =>
                this.props.history.push({
                  pathname: "/main/floor",
                  search: "",
                  state: {
                    date: jsdate,
                  },
                })
              }
            >
              <i class="im im-date-o"></i>
              {date}
            </span>
            <span>
              <i class="im im-clock-o"></i>
              {this.props.data.time}
            </span>
            <span>
              <i class="im im-users"></i>
              {this.props.data.pax}
            </span>
            <span style={{ fontWeight: "bold" }}>{seatPreference}</span>
            <button
              onClick={() => {
                this.setState({
                  open: !this.state.open,
                });
              }}
            >
              <i style={{ color: "#000" }} class="im im-care-down"></i>
            </button>
          </div>
          {this.props.data.remarks !== "" ? (
            <div className="remarks">
              <span>{this.props.data.remarks}</span>
            </div>
          ) : null}

          {this.state.open ? (
            <div className="contact-details">
              <span
                style={
                  this.props.data.contactPref === "mobile"
                    ? {
                      backgroundColor: "#e6a44a",
                      color: "#000",
                      borderRadius: "0.3em",
                    }
                    : null
                }
              >
                <i class="im im-mobile"></i>
                {this.props.data.mobile}
              </span>
              <span
                style={
                  this.props.data.contactPref === "Email"
                    ? {
                      backgroundColor: "#e6a44a",
                      color: "#000",
                      borderRadius: "0.3em",
                    }
                    : null
                }
              >
                <i class="im im-mail"></i>
                <a
                  href={`mailto:${this.props.data.email}`}
                  subject="Reservation"
                >
                  {this.props.data.email}
                </a>
              </span>
              {this.props.data.ht ? <span>
                <i class="im im-coffee"></i>
                {this.props.data.ht}
              </span> : null}
            </div>
          ) : null}
          {this.state.open ? (
            <div className="remarks">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span>Submission: {createDate}</span>
                  {this.props.data.acknowledgement ? <span>Acknowledged: {acknowledgeDateTime}</span> : null}
                </div>
                {this.state.open ? (
                  <div className="buttons">
                    <button
                      disabled={this.props.data.declined}
                      className="email-btn"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/main/edit/${this.props.data.id}`,
                        })
                      }
                    >
                      <i class="im im-edit"></i>Edit
                    </button>
                    <button className="arrive-btn" onClick={this.confirmCancel}>
                      <i class="im im-x-mark"></i>Cancel
                    </button>
                  </div>
                ) : null}
              </div>

              {this.props.data.editedAt ? (
                <span>Edited: {editedDate}</span>
              ) : null}
            </div>
          ) : null}
        </div>
        <hr style={{ borderColor: "#000" }}></hr>
        <div className="buttons">
          <button
            disabled={this.props.data.declined}
            style={
              this.props.data.confirmed ? { backgroundColor: "#3ee67e" } : this.props.data.declined ? { color: "#8d99ae" } : null
            }
            className="email-btn"
            onClick={this.toggleModal1}
          >
            Send Email Confirmation
          </button>
          <button
            disabled={this.props.data.confirmed}
            className="arrive-btn"
            style={
              this.props.data.declined
                ? { backgroundColor: "#f71b39" }
                : this.props.data.confirmed ? { color: "#8d99ae" } : null
            }
            onClick={this.toggleModal}
          >
            {this.props.data.declined ? "Declined" : "Decline"}
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(ReserveData);
