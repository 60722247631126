import React, { Component } from 'react'
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { db } from '../Firebase/firebase';
import { message } from 'flwww';
import { AppContext } from '../context/AppContext'
import HourBlock from './HourBlock';

export default class Admin extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: [],
            loading: "Update",
            adminEmail: "",
            viewerEmail: "",
            outletId: ""
        };
    }
    componentDidMount() {
        let outlet;
        if (this.context.outlet === "TCS") {
            outlet = "UE7VmGfyNhBMjm1LIHDu";
        }
        else {
            outlet = "bFNcmIQkj6xENfA5Cdpi";
        }
        db.collection("disableddays").doc(outlet).onSnapshot((doc) => {
            let timeStamps = doc.data().date;
            let days = timeStamps.map(timeStamp => {
                return timeStamp.toDate();
            })
            this.setState({
                selectedDays: days,
                outletId: outlet
            })
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
    }
    updateDays = (e) => {
        e.preventDefault();
        db.collection("disableddays").doc(this.state.outletId).update({ date: this.state.selectedDays });
        message("updated!", "success", 3);
    }
    addAdmin = () => {
        const adminData = {
            email: this.state.adminEmail
        }
        fetch("https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/addAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(adminData),
        }).then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.message) {
                    console.log(data.message);
                } else {
                    console.log("Failed");
                }
            });
    }

    addViewer = () => {
        const viewerData = {
            email: this.state.viewerEmail
        }
        fetch("https://us-central1-reservations-7dd65.cloudfunctions.net/widgets/addViewer", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(viewerData),
        }).then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.message) {
                    console.log(data.message);
                } else {
                    console.log("Failed");
                }
            });
    }

    /*
 <input type="email" name="email" id="adminEmail" value={this.state.adminEmail} onChange={this.onChange} />
                <button onClick={this.addAdmin}>Add Admin</button>
                <input type="email" name="email2" id="viewerEmail" value={this.state.viewerEmail} onChange={this.onChange} />
                <button onClick={this.addViewer}>Add Viewer</button>
    */
    render() {
        return (
            <div id="create">
                <div class="date-box">
                    <h1>Blocked Days</h1>
                    <form>
                        <div className="date-time full-width">
                            {" "}
                            <div className="form-group">
                                <DayPicker
                                    onDayClick={this.handleDayClick}
                                    selectedDays={this.state.selectedDays}
                                    onDayChange={(day) => console.log(day)}
                                    showOverlay={true}
                                />
                            </div>
                        </div>
                        <button className="full-width" type="submit" onClick={this.updateDays}>
                            {this.state.loading}
                        </button>
                    </form>
                </div>

                <HourBlock></HourBlock>
            </div>
        )
    }
}
