import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { Checkbox } from "flwww";

export default class TableRow extends Component {
  onTableChange = (e) => {
    db.collection("reservations")
      .doc(this.props.data.id)
      .update({ tableNo: e.target.value });
  };
  confirmArrival = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      finished: !this.props.data.finished,
    });
  };
  handleCheck = () => {
    db.collection("reservations").doc(this.props.data.id).update({
      turnedOver: !this.props.data.turnedOver,
    });
  };
  render() {
    return (
      <div
        className={
          this.props.data.cancelled
            ? "floor-table-row cancelled"
            : "floor-table-row"
        }
        style={
          this.props.data.finished
            ? { backgroundColor: "#3ee67e" }
            : this.props.data.declined
              ? { backgroundColor: "#f71b39" }
              : this.props.current > this.props.data.dateTime
                ? { backgroundColor: "orange" }
                : null
        }
      >
        <span>{this.props.data.time}</span>
        <span style={this.props.data.ht === "Yes" ? { fontWeight: "bold", color: "red" } : {}} onClick={this.confirmArrival}>{this.props.data.name}</span>
        <span>{this.props.data.mobile}</span>
        <span>{this.props.data.pax}</span>
        <span>{this.props.data.seatPref}</span>
        <span className="turn-over">
          <Checkbox
            name="checkbox"
            onChange={this.handleCheck}
            checked={this.props.data.turnedOver}
          ></Checkbox>
        </span>
        <span className="table-number">
          <input
            type="text"
            onChange={this.onTableChange}
            value={this.props.data.tableNo}
          ></input>
        </span>
        {this.props.data.remarks !== "" ? (
          <span className="table-remarks">{this.props.data.remarks}</span>
        ) : null}
      </div>
    );
  }
}
