import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { AppContext } from '../context/AppContext';
export default class Ops extends Component {
  static contextType = AppContext;
  state = {
    date: new Date(),
    data: [{}]
  }
  componentDidMount() {
    this.onDateChange(this.state.date);
  }

  onDateChange = (jsDate, dateString) => {
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let day = date.getDate();
          let month = date.getMonth();
          let year = date.getFullYear();
          db.collection("ops")
            .where("outlet", "==", this.context.outlet)
            .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
            .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
            .onSnapshot((querySnapshot) => {
              console.log(querySnapshot)
              if (querySnapshot.empty) {
                db.collection("ops").add({
                  createdAt: new Date(),
                  outlet: this.context.outlet,
                  date: jsDate,
                  host: "",
                  bar: "",
                  cafe: "",
                  dining: "",
                  drinksRun: "",
                  foodCaller: "",
                  foodRun: "",
                  runner: "",
                  alfresco: "",
                  cashier: "",
                  verandah: "",
                  float: ""
                })
              }
              else {
                let data = [];
                querySnapshot.forEach((doc) => {
                  let info = doc.data();
                  data.push({ ...info, id: doc.id });
                });
                this.setState({
                  data
                });
              }

            });
        }
      }
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    db.collection("ops")
      .doc(this.state.data[0].id)
      .update({ [e.target.id]: e.target.value });
  };
  render() {
    return (
      <div className="ops">
        <div className="dash-bar">
          <div className="dates">
            <DatePickerInput
              value={this.state.date}
              onChange={this.onDateChange}
            ></DatePickerInput>
          </div>
        </div>
        <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Host</label>
            <input disabled={true} type="text" id="host" onChange={this.onChange} value={this.state.data[0].host} />
          </div>
        </div>
        {this.context.outlet === "TCS" ? <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Cashier</label>
            <input disabled={true} type="text" id="cashier" onChange={this.onChange} value={this.state.data[0].cashier} />
          </div>
        </div> : null}
        <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">{this.context.outlet === "TCS" ? "Cafe" : "INDOOR"}</label>
            <input disabled={true} type="text" id="cafe" onChange={this.onChange} value={this.state.data[0].cafe} />
          </div>
        </div>
        <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">{this.context.outlet === "TCS" ? "Dining" : "P SECTION"}</label>
            <input disabled={true} type="text" id="dining" onChange={this.onChange} value={this.state.data[0].dining} />
          </div>
        </div>
        {this.context.outlet === "TCS" ? <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Alfresco</label>
            <input disabled={true} type="text" id="alfresco" onChange={this.onChange} value={this.state.data[0].alfresco} />
          </div>
        </div> : null}
        {this.context.outlet === "TCS" ? <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Verandah</label>
            <input disabled={true} type="text" id="verandah" onChange={this.onChange} value={this.state.data[0].verandah} />
          </div>
        </div> : null}
        <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">{this.context.outlet === "TCS" ? "Food Caller" : "M SECTION"}</label>
            <input disabled={true} type="text" id="foodCaller" onChange={this.onChange} value={this.state.data[0].foodCaller} />
          </div>
        </div>
        {this.context.outlet === "TCS" ? <React.Fragment>
          <div className="role-box">
            <i class="im im-10x im-id-card"></i>
            <div>
              <label htmlFor="role">Runner</label>
              <input disabled={true} type="text" id="runner" onChange={this.onChange} value={this.state.data[0].runner} />
            </div>
          </div>
          <div className="role-box">
            <i class="im im-10x im-id-card"></i>
            <div>
              <label htmlFor="role">Float</label>
              <input disabled={true} type="text" id="float" onChange={this.onChange} value={this.state.data[0].float} />
            </div>
          </div>
        </React.Fragment> : <React.Fragment><div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Food Run</label>
            <input disabled={true} type="text" id="foodRun" onChange={this.onChange} value={this.state.data[0].foodRun} />
          </div>
        </div>
          <div className="role-box">
            <i class="im im-10x im-id-card"></i>
            <div>
              <label htmlFor="role">Drinks Run</label>
              <input disabled={true} type="text" id="drinksRun" onChange={this.onChange} value={this.state.data[0].drinksRun} />
            </div>
          </div></React.Fragment>}

        <div className="role-box">
          <i class="im im-10x im-id-card"></i>
          <div>
            <label htmlFor="role">Bar</label>
            <input disabled={true} type="text" id="bar" onChange={this.onChange} value={this.state.data[0].bar} />
          </div>
        </div>
      </div>
    );
  }
}
