import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "flwww";
import { AppContext } from "./context/AppContext";
import { auth } from "./Firebase/firebase";
export const NavBar = (props) => {
  const App = useContext(AppContext);

  return (
    <div className="nav-filter-bar">
      <div className="links">
        <NavLink
          activeClassName="tcs-active"
          to={{
            pathname: `/main/floor`,
            state: { date: new Date() },
          }}
        >
          Tables
        </NavLink>
        <NavLink activeClassName="tcs-active" to={`/main/ops`}>
          Ops
        </NavLink>
      </div>
    </div>
  );
};
